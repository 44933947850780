import './App.css';

import Login from './Authentication/Login';

    
import React from 'react';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Avatar } from 'primereact/avatar';
import { InputText } from 'primereact/inputtext';
import CardComp from './components/CardComp';

function App() {


    const startContent = (
      <React.Fragment>
          {/* <Button rounded text icon="pi pi-desktop" style={{ marginRight: '10rem' }} onClick={() =>  window.open('/big-screen', "_blank")} /> */}
          {/* <img src="https://suleiman.rs4it.com/public/images%20copy/logo-dark12.png" alt="saudi-cup" height={40} /> */}
      </React.Fragment>
    );


    // const endContent = (
    //   <React.Fragment>
    //       {/* <Avatar icon="pi pi-user" size="large" shape="circle" /> */}
    //   </React.Fragment>
    // );

  return (    
    <div className="card">
        {/* <Toolbar end={startContent} /> */}
        <CardComp />
    </div>
  )        
}

export default App;
