import Farasan from "../assets/images/1.png";
import BlueHoll from "../assets/images/2.png";
import logo from "../assets/images/logo.png";

import N1 from "../assets/images/1-F.png";
import N2 from "../assets/images/2-B.png";

import axios from "axios";

function CardComp() {
  const submit = async (_params) => {
    try {
      const _result = await axios.post(
        "https://lana.rs4it.com/Island/api/set",
        { island: _params }
      );

      // window.open('/big-screen', '_blank')

      console.log("_result: ", _result?.data);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <div
      style={{
        height: "80vh",
        display: "flex",
        flexDirection:"column", 
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        alt="logo"
        src={logo}
        width={"300"}
        className="mt-7"
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />

      <div
        className="mt-8"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <img
            alt="محمية جزر فرسان"
            src={Farasan}
            style={{ cursor: "pointer" }}
            className="md:w-30rem"
            onClick={() => submit(1)}
          />

          <img
            alt="محمية جزر فرسان"
            src={N1}
            style={{
              width: "200px",
              display: "block",
              marginTop: "-30px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </div>

        <div className="ml-8">
          <img
            alt="الثقوب الزرقاء"
            src={BlueHoll}
            style={{ cursor: "pointer" }}
            className="md:w-30rem"
            onClick={() => submit(2)}
          />

          <img
            alt="الثقوب الزرقاء"
            src={N2}
            style={{
              width: "200px",
              display: "block",
              marginTop: "-30px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CardComp;
