import Pusher from "pusher-js";
import Video from "../assets/videos/0.mp4";
import VideoF from "../assets/videos/1.mp4";
import VideoB from "../assets/videos/2.mp4";
import { Fragment, useEffect, useState } from "react";
import Image from '../assets/images/background.jpeg';

function BigScreenPage() {
  const [island, setIsland] = useState(0);
  const [isOff, setIsOff] = useState(1);

  useEffect(() => {
    const pusher = new Pusher("32e4005df6e0fe20780a", { cluster: "ap2" });
    const channel = pusher.subscribe("island");

    channel.bind("MainScreenSettingsUpdated", (data) => {
      setIsland(data?.message?.island);
      setIsOff(0);
    });

    return () => {
      channel.unbind(); // Unbind the event listeners when the component unmounts
      pusher.unsubscribe("island"); // Unsubscribe from the channel
    };
  }, []);

  const handleVideoEnd = () => {
    setIsOff(1); // Set isOff to 1 when the video ends
  };

  // const videoSrc = island === 0 ? Video : island === 1 ? VideoF : VideoB;
  const mediaSrc = isOff
    ? Image
    : island === 0
    ? Video
    : island === 1
    ? VideoF
    : VideoB;

  const videoStyle = {
    width: "100%",
    height: "100vh",
    objectFit: "cover",
    display: "block",
    margin: "0",
  };
  return (
    <Fragment key={mediaSrc}>
      {isOff ? (
        <div
          className="image-container"
          style={{
            backgroundImage: `url(${mediaSrc})`,
            backgroundSize: "cover",
            width: "100%",
            height: "100vh",
          }}
        ></div>
      ) : (
        <video
          autoPlay
          controls
          onEnded={handleVideoEnd}
          style={videoStyle}
        >
          <source src={mediaSrc} type="video/mp4" />
        </video>
      )}
    </Fragment>
  );
}

export default BigScreenPage;
